import { useEventListener } from "./useEventListener.mjs";
export const useOnClickOutside = (rootEl, callback) => {
  useEventListener(window, "mouseup", (e) => {
    const clickedEl = e.target;
    if (rootEl.value?.contains(clickedEl)) {
      return;
    }
    callback();
  });
};
