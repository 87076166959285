import { watch, onBeforeUnmount, unref, isRef } from "vue";
export const useEventListener = (target, event, handler) => {
  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      oldValue?.removeEventListener(event, handler);
      value?.addEventListener(event, handler);
    });
  } else {
    target.addEventListener(event, handler);
  }
  onBeforeUnmount(() => {
    unref(target)?.removeEventListener(event, handler);
  });
};
